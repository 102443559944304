<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create User Dialog"
    color="info"
  >

    <template #header>
      <h6 class='modal-title text-center'>사용자 {{title}}</h6>
    </template>

    <div class='modal-body'>
      <form name='userForm'>
        <div class='form-group' ng-class='{ "has-error": id.inputted && !id.valid }'>
          <div class='row'>
            <div class='col-md-8'>
              <label>아이디 <strong class='text-danger'>*</strong></label>
              <input type='text' class='form-control' placeholder='사용자 아이디' v-model='user.username' :disabled='isEditMode'>
            </div>
            <div class='col-md-4'>
              <label>권한</label>
              <select class='form-control' style="height:34px" v-model='user.group_guid' :disabled='!isAdmin'>
                <option v-for="group in group_list" :value="group.guid" v-bind:key="group.guid">{{group.name}} </option>
              </select>
            </div>
          </div>
        </div>

        <div class='form-group'>
          <div class='row'>
            <div class='col-md-6'>
              <label>비밀번호 <strong class='text-danger'>*</strong></label>
              <input type='password' class='form-control' placeholder='비밀번호' v-model='user.password' @change='validatePassword(user.password, user.confirm)' required>
            </div>
            <div class='col-md-6'>
              <label>비밀번호 확인 <strong class='text-danger'>*</strong></label>
              <input type='password' class='form-control' placeholder='비밀번호 확인' v-model='user.confirm' @change='validatePassword(user.password, user.confirm)' required>
              <label v-bind:class='{ "text-primary": password.valid, "text-danger": !password.valid }' v-show='password.inputted'>
                {{ (password.valid)? "비밀번호 일치" : "비밀번호 불일치" }}
              </label>
            </div>
          </div>
        </div>
        <div class='form-group'>
          <div class='row' v-if="is_admin==true">
            <div class='col-md-8'>
              <label>이름 <strong class='text-danger'>*</strong></label>
              <input type='text' class='form-control' placeholder='이름' v-model='user.realm' required>
            </div>
            <div class="col-md-4">
              <label>서비스 타입</label>
              <select class='form-control' style="height:34px" v-model='user.service_type' :disabled='!isAdmin'>
                <option v-for="service in service_type_info" :value="service.key" v-bind:key="service.key">{{service.value}} </option>
              </select>
            </div>
          </div>
          <div v-else>
            <label>이름 <strong class='text-danger'>*</strong></label>
            <input type='text' class='form-control' placeholder='이름' v-model='user.realm' required>
          </div>
        </div>
        <div class='form-group'>
          <label>전화</label>
          <input type='text' class='form-control' placeholder='전화번호' v-model='user.tel'>
        </div>
        <div class='form-group'>
          <label>이메일 <strong class='text-danger'>*</strong><small>(예: user@tech9.kr)</small></label>
          <input type='email' class='form-control' name='email' placeholder='이메일' v-model='user.email'>
        </div>

        <hr>
        <div class='form-group' v-show='isAdmin==true'>
          <label>관리 사이트 <strong class='text-danger'>*</strong><small>(상황판 표기용)</small></label>
          <br>

          <span v-if="!site_list || site_list.length ==0">
            <strong class='text-danger'>등록된 사이트가 존재하지 않습니다.<br> 사이트를 추가하세요.</strong>
          </span>

          <v-table id="siteTable" data-toolbar="#toolbar" :columns="columns" :data="site_list" :options="options" @on-check="onUpdate" @on-uncheck="onUpdate"></v-table>
        </div>

        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>기준 사이트 <strong class='text-danger'>*</strong><small>(맵 중심 표기용)</small></label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-12'>
              <select class='form-control' style="height:34px" v-model='user.default_site_guid' :disabled='!isAdmin'>
                <option v-for="site in fitered_site" :value="site.guid" v-bind:key="site.guid">{{site.name}} </option>
              </select>
            </div>
          </div>
        </div>

      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">등록</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
  
    <Notify ref="notifyDialog"/>

  </KModal>

</template>

<script>
import Vue from 'vue';
import loopback from '@/services/loopback';
import utils from '@/services/utils';
import { SITE_TABLE_OPTIOINS, SITE_TABLE_COLUMS } from "@/constants/userSettings"

var bcrypt = require('bcryptjs');
var SERVICE_TYPE = utils.getServiceTypes();

export default {
  name: 'CreateUser',
  props: {
    group_list: {
      type: Array
    },
    isAdmin: Boolean
  },
  data () {
    return {
      showModal: false,
      title: '생성',
      which: 'create',
      user: {},
      site_list: [],
      id: {
        valid: false,
        inputted: ''
      },
      password: {
        valid: false,
        inputted: ''
      },
      options: SITE_TABLE_OPTIOINS,
      columns: SITE_TABLE_COLUMS,
      isEditMode: false,
      is_admin: this.$store.state.auth.is_admin,
      service_type_info: _.map(SERVICE_TYPE.USER, function(value, key) {
        return { key: key, value: value}}
      )
    }
  },
  created() {
    if (this.which === 'update') {
      this.password.valid = true;
    }
  },
  computed: {
    fitered_site() {
      return _.isUndefined(this.site_list) ? [] : this.site_list.filter(item => item.checked);
    },
    // default_site() {
    //   return _.isUndefined(this.user.default_site_guid) ? '' : this.user.default_site_guid
    // }
  },
  watch: {
    // user: {
    //   deep: true,
    //   handler(val) {
    //     Vue.$log.debug('CreateUser::watch user:', val)
    //   }
    // }
  },
  methods: {
    onUpdate(row, $element) {
      this.site_list = $('#siteTable').bootstrapTable('getData');
      this.scrollTo();
    },
    scrollTo() {
      setTimeout(function() {
        var data = $('#siteTable').bootstrapTable('getData');
        var site = _.first(data.filter(item => item.checked));
        var index = _.findIndex(data, {name: site.name});
        $('#siteTable').bootstrapTable('scrollTo', { unit: 'rows', value: index });
      }, 100)
    },
    validatePassword(inputPassword, confirmPassword) {
      if (!inputPassword || !confirmPassword) {
        this.password.inputted = false;
        this.password.valid = false;
        return;
      }
      this.password.inputted = true;
      this.password.valid = (inputPassword === confirmPassword);
    },
    convertData(data) {
      var result = _.cloneDeep(data);
      if (_.get(result, "confirm"))
        delete result.confirm;

      if (_.get(result, "group_name"))
        delete result.group_name;

      var password = this.hashPassword(result.password);
      if (!password) {
        delete result.password;
      } else {
        result.password = password;
      }
      result.site_list = []
      this.site_list.filter(r => r.checked).forEach(r => result.site_list.push(r.guid))
      return result;
    },
    hashPassword(plain) {
      // this function equals to loopback hash function.
      if (!plain || plain == "default_hidden") {
        return undefined;
      }
      var password = plain;
      // var salt = bcrypt.genSaltSync(this.settings.saltWorkFactor || SALT_WORK_FACTOR);
      var salt = bcrypt.genSaltSync(0 || 10);
      var hashed = bcrypt.hashSync(password, salt);
      return hashed;
    },
    createUser() {
      var converted = this.convertData(this.user);
      Vue.$log.debug('CreateUser::createUser convert: ', JSON.stringify(converted,null,2))

      var filter = {
        fields: { components: true }
      }
      var dictionary = undefined
      var new_user = undefined
      this.$store.dispatch('rest/find', {model:'dictionaries', filter:filter})
        .then(res => {
          dictionary = _.first(res)
          return loopback
            .upsert('/user', converted)
        })
        .then((res) => {
          new_user = res
          Vue.$log.debug('user created : ', new_user)
          var data = {
            user_guid: new_user.guid,
            components: dictionary.components
          }
          return loopback
                    .upsert('dashboards', data)
        })
        .then((dashboard) => {
          Vue.$log.error('dashboard created :', dashboard)
          let msg = {
            name: this.$options.name,
            which: this.which,
            result: new_user
          }
          this.$emit('hide', msg)
        })
        .catch((err) => {
          Vue.$log.error('createUser error: ', err.toString())
        })
    },
    updateUser() {
      let site_guids = []
      this.site_list.forEach(e => {
        if (e.checked) site_guids.push(e.guid)
      })
      this.user.site_guids = site_guids

      var where = {
        guid: this.user.guid
      }

      loopback
        .upsertWithWhere('/user', where, this.user)
        .then((user) => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: user
          }
          this.$emit('hide', msg)
        })
        .catch((err) => {
          Vue.$log.error('updateUser error: ', err.toString())
        })
    },
    register() {
      this.which === 'create' ? this.createUser() : this.updateUser();
      this.showModal = false
    },
    cancel() {
      this.showModal = false
    },
    show(user, site_list) {
      var self = this;
      this.user = user;
      this.site_list = site_list;
      this.title = _.isEmpty(this.user) ? '생성' : '변경'
      this.which = _.isEmpty(this.user) ? 'create' : 'update'
      this.isEditMode = !_.isEmpty(this.user)
      this.showModal = true

      this.scrollTo();
    }
  }

}
</script>
<template>

<div>
  <CCard>
    <CCardBody class="pt-0">
      <div id="toolbar" class="pt-2">
        <strong class="pl-1">사용자 관리</strong>
      </div>
      <v-table id="userTable" data-toolbar="#toolbar" :columns="columns" :data="data" :options="options" @on-check="onCheck" @on-uncheck="onUncheck"></v-table>
    </CCardBody>
    <CCardFooter>
      <CRow class="text-center">
        <CCol col="12" xl="12">
          <CButton color="warning" class="float-right" @click="openDeleteDialog()" v-show="isAdmin" :disabled="permission.delete == false || selected_row == null">
            삭제
          </CButton>
          <CButton color="info" class="float-right mr-1" @click="openDialog('update')" :disabled="permission.update == false || selected_row == null" >
            수정
          </CButton>
          <CButton color="success" class="float-right mr-1" @click="openDialog('create')" v-show="isAdmin" :disabled="permission.create == false" >
            생성
          </CButton>
          <CButton color="warning" class="float-right mr-1" @click="openResetDialog('reset')" v-show="isAdmin && selected_row !== null" :disabled="permission.create == false || selected_row == null" >
            비번 리셋
          </CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>

  <Notify ref="notifyDialog"/>
  <Confirm
      ref="confirmDialog"
      title="확인"
      which="delete"
      @hide="hideModal"
  />
  <CreateUser
      ref="userDialog"
      :group_list.sync="group_list"
      :isAdmin.sync= "isAdmin"
      @hide="hideModal"
  />
  <DashboardSetting
      ref="settingDialog"
      @hide="hideModal"
  />
</div>

</template>

<script>
import Vue from 'vue';
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm'

import { USER_TABLE_OPTIOINS, USER_TABLE_COLUMS } from "@/constants/userSettings"

import CreateUser from '@/views/user/CreateUser'
import DashboardSetting from '@/views/user/DashboardSetting'

import utils from '@/services/utils'

const PERMITTED = 'user';
var SERVICE_TYPE = utils.getServiceTypes();

export default {
  name: 'UserManage',
  components: {
    BootstrapTable: BootstrapTable,
    CreateUser: CreateUser,
    DashboardSetting: DashboardSetting
  },
  props: {
    prefix: {
      type: String,
      default: ''
    }
  },
  created() {
    USER_TABLE_OPTIOINS.search = true;

    this.user = this.$store.state.auth.user_info.user;
    this.group = this.$store.state.auth.user_info.group;
    this.group_list = this.$store.state.auth.user_info.group_list;
    this.sites = this.$store.state.auth.user_info.sites

    if (this.isAdmin) this.options.showColumns = false;
    _.find(this.columns, { field: 'created_at' }).formatter = this.dateFormatter;
    _.find(this.columns, { field: 'group_name' }).formatter = this.groupNameFormatter;
    _.find(this.columns, { field: 'site_guids' }).formatter = this.siteNameFormatter;
    _.find(this.columns, { field: 'service_type' }).formatter = this.serviceFormatter;
    Vue.$log.debug('CreateUser::user_info: ', JSON.stringify(this.$store.state.auth.user_info,null,2))
    Vue.$log.debug('CreateUser::is_mobile: ', this.$is_mobile)

    var filtered = this.group.role.filter(function(e) {
      return e.type == PERMITTED;
    })[0];
    this.permission = filtered.permission
    this.active_site_name = _.get(this.user, "active_site_name", "테크나인");
    
    this.getBaseInfo();
  },
  mounted() {
    // $('#userTable').on('click-row.bs.table', function(element, field, value, row) {
    //   if (value === 0) {
    //     // no dialog
    //     return
    //   }
    //   this.selected_row = field
    // })
    // $('#userTable').on('check.bs.table', function(e, row, $element) {
    //   this.selected_row = row;
    // });

    // $('#userTable').on('uncheck.bs.table', function(e, row, $element) {
    //   this.selected_row = undefined;
    // });
  },
  data () {
    return {
      isAdmin: this.$store.state.auth.is_admin,
      user: {},
      group: {},
      group_list: [],
      permission: {},
      sites: [],
      site_list: [],
      options: USER_TABLE_OPTIOINS,
      columns: USER_TABLE_COLUMS,
      data: [],
      selected_row: null
    }
  },

  computed: {
  },
  
  methods: {
    dateFormatter(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    siteNameFormatter(value, row, index) {
      var result = [];
      var self = this
      var filtered = value.filter(function(v) {
        return _.find(self.site_list, function(s) {
          if (s.guid == v) {
            result.push("<a href='#/{0}sites/{1}'>{2}</a>".format(self.prefix, s.guid, s.name));
            return true;
          }
        })
      })
      return result;
    },
    groupNameFormatter(value, row, index) {
      if (!this.group_list) {
        return value;
      }
      var filtered = this.group_list.filter(function(e) {
        return e.guid == value;
      })[0];
      if (!filtered) {
        return value;
      }
      return filtered.name;
    },
    serviceFormatter(value, row, index) {
      return _.get(SERVICE_TYPE.USER, value, '토양정보');
    },
    hideModal(params) {
      if (_.isUndefined(params)) {
        return; // cancel command
      }
      switch (params.name) {
        case 'Confirm':
          if (params.which === 'delete') this.deleteUser(this.selected_row)
          else if (params.which === 'reset') this.resetMail(this.selected_row)
          break;
        case 'CreateUser':
          this.getBaseInfo()
          break;
        default:
          break
      }
    },
    onClickRow(field, value, row) {
      this.selected_row = field
    },
    onCheck(row, $element) {
      this.selected_row = row;
    },
    onUncheck(row, $element) {
      this.selected_row = null;
    },
    isSelected() {
      return this.selected_row !== null;
    },
    deleteUser(user) {
      let where = {
        guid: user.guid
      }
      loopback
        .upsertWithWhere('/user', where, { delete: true })
        .then((res) => {
          this.getBaseInfo()
        })
        .catch((err) => {
          Vue.$log.error('UserManage::deleteUser error: ', err.toString())
        })
    },
    resetMail(user) {
      loopback
        .resetMail(user.username, user.email)
        .then((res) => {
          this.$refs.notifyDialog.show('메일 전송 : ' + res.statusText)
        })
        .catch((err) => {
          Vue.$log.error('UserManage::resetMail error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    openDeleteDialog() {
      if (this.selected_row === null) {
        Vue.$log.error('ERROR: you must be select an user');
        return
      }
      let message = '사용자 : ' + this.selected_row.email + ' 를 삭제 하시겠습니까?'
      this.$refs.confirmDialog.show(message, '사용자 삭제', "확인", "delete");
    },
    openResetDialog() {
      if (this.selected_row === null) {
        Vue.$log.error('ERROR: you must be select an user');
        return
      }
      let message = '사용자 : ' + this.selected_row.email + ' 에 리셋 메일을 전송 하시겠습니까?'
      this.$refs.confirmDialog.show(message, '리셋 메일 전송', "확인", "reset");
    },
    openDialog(which) {
      if (which === 'update' && !this.selected_row) {
        Vue.$log.error('ERROR: you must be select an user');
        return
      }
      if (this.selected_row) {
        delete this.selected_row.group_name
      }
      let user = which === 'create' ? {} : _.cloneDeep(this.selected_row);
      this.$refs.userDialog.show(user, this.getSiteList(which));
    },
    openComponentDialog() {
      var current_user = this.selected_row !== null ? this.selected_row: this.user ;
      var filter = {
        fields: { components: true }
      }
      loopback
        .find('/dictionaries', filter)
        .then((result) => {
          var dictionary = _.first(result);
          Vue.$log.debug('openComponentDialog current_user: ', JSON.stringify(current_user,null,2))
          var filter = {
            where: {
              user_guid: current_user.guid
            }
          }
          loopback
            .find('/dashboards', filter)
            .then((r) => {
              var dashboard = _.first(r);
              dictionary.components.forEach(function(el) {
                if (!_.find(dashboard.components, {
                    title: el.title
                  })) {
                  dashboard.components.push(el)
                }
              })
              var order = 0;
              dashboard.components.forEach(el => {
                el.order = order++;
                if (_.isArray(el.icon)) {
                  return el
                }
                el.icon = _.map(el.icon.split(' '), el => {
                  el = el.replace('line-chart', 'chart-line')
                  el = el.replace('exchange', 'exchange-alt')
                  el = el.replace('humidity', 'cloud-rain')
                  return el.replace('fa-', '')
                })
              })
              var components = [];
              var nonSelected = dashboard.components.filter(function(t) {
                return t.enable === false;
              })

              var selected = dashboard.components.filter(function(t) {
                return t.enable === true;
              })

              components.push(nonSelected);
              components.push(selected);

              this.$refs.settingDialog.show(components, current_user.realm, dashboard.guid);
            })
        })
        .catch((err) => {
          Vue.$log.error('UserManage::openComponentDialog error: ', err.toString())
        })
    },
    getSiteList(which) {
      let site_guids = which === 'create' ? [] : 
        this.selected_row ? this.selected_row.site_guids : []

      let rows = _.cloneDeep(this.site_list)
      for (var i = 0; i < site_guids.length; i++) {
        var e = _.find(rows, {guid : site_guids[i]})
        if (!_.isEmpty(e)) {
          e.checked = true
        }
      }
      return rows
    },
    getBaseInfo() {
      var self = this;
      this.selected_row = null;

      var data = {
        where: {
          and: [{
            delete: false
          }]
        }
      }

      var getSites = function() {
        var filter = _.cloneDeep(data);
        if (!_.isEmpty(self.group) && self.group.name !== 'admin') {
          filter.where.and.push({
            guid: { inq: self.user.site_guids }
          })
        }
        return loopback
          .find('/sites', filter)
          .then((sites) => {
            var rows = [];
            sites.forEach(function(e) {
              e["checked"] = false;
              rows.push(e);
            });
            return Promise.resolve(rows);
          })
          .catch((err) => {
            Vue.$log.error('find site error : ', err.toString())
            return Promise.reject(err);
          })
      }

      getSites().then((r) => {
        this.site_list = r;
        var active_site_guid = _.find(this.site_list, { 'name' : this.active_site_name }).guid;

        if (!_.isEmpty(this.group) &&
            this.group.name !== 'admin') {
          let site_guids = []
          this.sites.forEach(site => site_guids.push(site.guid));
          data.where.and.push({
            guid: this.user.guid
          })
          data.where.and.push({
            site_guids: { inq: site_guids }
          })
          // data.where.and.push({
          //   group_guid: this.group.guid
          // })
        }

        loopback
          .find('user', data)
          .then((users) => {
            var rows = [];
            users.forEach(function(e) {
                rows.push({
                  username: e.username,
                  realm: e.realm,
                  password: e.password,
                  email: e.email,
                  group_guid: e.group_guid,
                  site_guids: e.site_guids,
                  default_site_guid: e.default_site_guid,
                  group_name: e.group_guid,
                  created_at: e.created_at,
                  guid: e.guid,
                  service_type: e.service_type
                })
            });
            this.data = rows
            setTimeout(function() {
              var admin_group = _.find(self.group_list, {group_type: 'admin_type'});
              var data = $('#userTable').bootstrapTable('getData');
              var index = _.findIndex(data, function(user) {
                return user.group_guid != admin_group.guid && user.site_guids.indexOf(active_site_guid) > -1;
              });
              if (index < 0) return;
              $('#userTable').bootstrapTable('scrollTo', { unit: 'rows', value: index });
              $('#userTable').bootstrapTable('check', index);
            }, 100)

          })
          .catch(err => {
            Vue.$log.error('find user error : ', err.toString())
          })
      })
    }, // getBaseInfo
  }
}
</script>